import Router from "next/router";
import Head from "next/head";
import Image from "next/image";
import styles from "../styles/Home.module.css";
import { signIn, signOut, useSession, getSession, getProviders } from "next-auth/react";
// import { GetServerSideProps } from "next"
// import type { Session } from "next-auth"
import { Typography } from "@mui/material";
import Button from "@mui/material/Button";

export default function Home({ providers = [] }) {
    const { data: session, status } = useSession();
    const loading = status === "loading";

    console.log(session);
    if (session && !loading) {
        Router.push("/account");
    }

    return (
        <div className={styles.container}>
            <Head>
                <title>Weave</title>
            </Head>

            <main className={styles.main}>
                <Image
                    src="/images/logos/logo-no-boarder.png"
                    width="384"
                    height="250"
                    alt="Weave"
                />

                <p className={styles.description}>
                    {!session && (
                        <>
                            <Button variant="contained" onClick={() => signIn("auth0")}>
                                Sign in / Sign Up
                            </Button>
                        </>
                    )}
                </p>

                <div className={styles.grid}></div>
            </main>

            <footer className={styles.footer}>
                <Typography>
                    Powered by <span className={styles.brand}>Weave</span>
                </Typography>
            </footer>
        </div>
    );
}

// Export the `session` prop to use sessions with Server Side Rendering
// export async function getServerSideProps(context: GetSessionOptions | undefined) {
//   const providers = await getProviders()
//   return {
//     props: {
//       session: await getSession(context),
//       providers: providers
//     }
//   }
// }
export async function getServerSideProps(context) {
    const providers = await getProviders();
    const session = await getSession(context);
    if (session !== undefined && session !== null) {
        return {
            redirect: {
                permanent: false,
                destination: "/account",
            },
        };
    }

    return {
        props: {
            session: session,
            providers: providers,
        },
    };
}
